import React from "react";

import { Logo, Wrapper } from "./styles";

import logo from "@images/logo.svg";

const Header: React.FC = () => {
  return (
    <Wrapper>
      <a href="https://haltonhillshydro.com/">
        <Logo src={logo} alt="Back to Website" />
      </a>
    </Wrapper>
  );
};

export default Header;
