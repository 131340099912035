import { coordinates } from "../config";

const useBounds = () => {
  const bounds = new (window as any).google.maps.LatLngBounds();
  coordinates.forEach((point: Point) => {
    var latLng = new (window as any).google.maps.LatLng(point.lat, point.lng);
    bounds.extend(latLng);
  });

  return bounds;
};

export default useBounds;
