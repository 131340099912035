import React from "react";

import { Wrapper } from "./styles";

const Footer = () => (
  <Wrapper>
    <p>
      &copy;2020, Halton Hills Hydro. Website by{" "}
      <a
        href="https://floating-point.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        floating-point
      </a>
      .
    </p>
  </Wrapper>
);

export default Footer;
