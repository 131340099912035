import React from "react";
import emotionNormalize from "emotion-normalize";
import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";

export const GlobalStyles = () => (
  <Global
    styles={css`
      ${emotionNormalize}
      *, *::after, *::before {
        box-sizing: border-box;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
      }
      body {
        font-family: system-ui, -apple-system, "Segoe UI", Roboto,
          "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
          "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
          "Noto Color Emoji";
        font-size: 16px;
        margin: 0;
        min-height: 100vh;
        padding: 0;
      }
      .pac-container {
        z-index: 9999;
      }
    `}
  />
);

export const Main = styled.main`
  position: relative;
  margin: 0 auto;
  max-width: 968px;
  padding: 1rem;
  padding-bottom: 3rem;
  z-index: 1;
`;

export const Em = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
`;
