import React, { useState, createRef, useEffect } from "react";
import { Formik, Form as FormikForm, FormikHelpers } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";

import Address from "./Address";
import Field from "./Field";
import File from "./File";
import Phone from "./Phone";
import TextArea from "./TextArea";
import Select from "./Select";
import Submit from "./Submit";

import { FormRow, Alert } from "@theme/form";
import { Em } from "@theme/global-styles";
import { Modal } from './NotificationModal'

interface FormValues {
  name: string;
  phone: string;
  address: string;
  condition: number;
  comments: string;
  file: string;
  longitude: number;
  latitude: number;
}

const Form = () => {
  const recaptchaRef = React.useRef(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const initialValues: FormValues = {
    name: "",
    phone: "",
    address: "",
    file: "",
    condition: 0,
    comments: "",
    longitude: 0,
    latitude: 0,
  };
  const [isShown, setIsShown] = useState<boolean>(true);
  const toggle = () => setIsShown(false);
  const [currentDate, setCurrentDate] = useState<string>('');
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    phone: Yup.string().required("Phone number is required."),
    address: Yup.string().required(
      "Invalid address, please select address from dropdown for increased accuracy."
    ),
    latitude: Yup.number(),
    longitude: Yup.number(),
    condition: Yup.number(),
    file: Yup.mixed(),
    comments: Yup.string(),
  });

  const conditionOptions = [
    { value: 1, label: "Tree on Line" },
    { value: 2, label: "Wires Down" },
    { value: 3, label: "Vehicle Accident" },
    { value: 4, label: "Broken Pole" },
    { value: 5, label: "Damaged Equipment" },
    { value: 6, label: "Pole Fire" },
    { value: 7, label: "Unknown" },
  ];

  const handleSubmit = (
    values: FormValues,
    { setFieldValue, setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (recaptchaRef) {
      if (recaptchaRef.current) {
        // @ts-ignore
        recaptchaRef.current.execute();
      }
    }
    if (!values.latitude || !values.longitude) {
      var geocoder = new google.maps.Geocoder();

      geocoder.geocode({ address: values.address }, function (results, status) {
        if (status == "OK") {
          var position = results[0].geometry.location;
          setFieldValue("latitude", position.lat());
          setFieldValue("longitude", position.lng());
          postOutage(values, setSubmitting);
        } else {
          alert(
            `We're sorry, we had a problem getting the location of the address entered ${values.address}. Please try again with a different address.`
          );
          return false;
        }
      });
    } else {
      postOutage(values, setSubmitting);
    }
  };


  useEffect(() => {
    // const date = new Date
    // const todaysDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
    //   if(localStorage.getItem("today") !== todaysDate){
    //     setTimeout(() => {
    //       localStorage.setItem("today", todaysDate)
    //     }, 2000)
    //     setIsShown(true)
    //   }
    //   else {
    //     setIsShown(false)
    //   }
  })

  const postOutage = (values: FormValues, setSubmitting: Function) => {
    const {
      name,
      phone,
      address,
      latitude,
      longitude,
      condition,
      comments,
      file,
    } = values;

    const url = `${process.env.RAZZLE_SUBMISSION_URL}?api_token=${process.env.RAZZLE_OUTAGE_API_KEY}`;
    const opts: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        phone,
        address,
        latitude,
        longitude,
        condition,
        comments,
        image: file,
      }),
    };

    fetch(url, opts)
      .then((res) => res.json())
      .then((res) => {
        setShowSuccess(true);
        setSubmitting(false);
      })
      .catch((err) => {
        window.console.log(err);
        setSubmitting(false);
      });
  };

  return (
    <>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <FormikForm>
        {showSuccess ? (
          <Alert>
            <p>Thanks for contacting us! Your outage has been reported.</p>
          </Alert>
        ) : (
          <>
            <p>
              Before you report an outage, please check{" "}
              <a href="https://outagemap.haltonhillshydro.com/">our map</a>. If
              the area is already highlighted – indicating a known power outage
              – you don’t need to do anything. Our crews are already aware of
              the outage and working to restore power.
            </p>
            <p>Thank you for taking the time to report a power outage.</p>
            <p>
              We provide service within the Town of Halton Hills. If you are
              outside of this area, please contact your local utility. You can
              find your local utility <a href="https://www.ieso.ca/learn/ontario-power-system/overview-of-sector-roles/find-your-ldc" target="_blank">here</a>.
            </p>
            <p>We do not reply directly to these forms.</p>
            <Em>If this is a life threatening emergency, please call 911.</Em>
            <Em>
              If this is an electrical emergency (such as wires down), please
              call us at 519-853-3701.
            </Em>
            {showError ? (
              <Alert error>
                <p>
                  We're sorry, we had problems processing your submission.
                  Please try again later.
                </p>
              </Alert>
            ) : null}
            <FormRow>
              <Field name="name" type="text" label="Name" required />
              <Phone name="phone" label="Phone" />
            </FormRow>
            
            <Address
              name="address"
              type="text"
              label="Outage Address"
              autocomplete="no"
              required
            />
            <FormRow>
              <Select
                name="condition"
                label="Did you see a safety hazard? (e.g. wires down, vehicle accident)"
                placeholder="If so, select a safety hazard"
                options={conditionOptions}
              />
              <File name="file" label="Attach an Image" />
            </FormRow>
            <TextArea name="comments" label="Comments/Details" />
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.RAZZLE_RECAPTCHA_KEY || ""}
            />
            <Submit />
          </>
        )}
      </FormikForm>
    </Formik>
     {/* <Modal isShown={isShown} hide={toggle} /> */}
    </>
  );
};

export default Form;
