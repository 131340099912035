import React from "react";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { ThemeProvider } from "emotion-theming";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import { useLoadScript } from "@react-google-maps/api";
import "@reach/skip-nav/styles.css";

import Header from "@components/Header";
import Footer from "@components/Footer";
import Form from "@components/Report";

import theme from "@theme/index";
import { GlobalStyles, Main } from "@theme/global-styles";

const libraries: Libraries = ["places"];

const App = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.RAZZLE_GOOGLE_API_KEY}`,
    libraries,
  });

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <SkipNavLink>Skip to content</SkipNavLink>
      <Header />
      <SkipNavContent>
        <Main>
          <h1>Report a Power Outage</h1>
          {isLoaded && !loadError ? <Form /> : null}
        </Main>
      </SkipNavContent>
      <Footer />
    </ThemeProvider>
  );
};

export default App;
